.attachment-actionBar{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-top: 15px;
    .btn{
        padding: 0;
        border-radius: 8px;
        background-color: #f8f8f8;
        color: #6d8cba;
        font-size: 20px;
        width: 45px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.card--whitebg{
    background-color: #FFF;
    border-radius: 5px;
    min-height: 600px;
    padding: 10px;
}

// .button-group{
//     margin-bottom: 10px;
//     .btn{
//         margin-right: 8px;
//     }
// }


.card--gray{
    border-radius: 5px;
    background-color: #f8f8f8;
    min-height: 600px;
    padding: 0;
}