//
// accordion.scss
//
.accordion {
  .card {
    margin-bottom: 15px;
    box-shadow: none;
  }

  .card-header {
    padding: 5px;
    border-radius: 7px !important;
  }
}

.custom-accordion {
  .accordion-list {
    display: flex;
    border-radius: 7px;
    background-color: $gray-300;
    // padding: 12px 20px;
    color: $body-color;
    font-weight: $font-weight-semibold;
    align-items: center;
    justify-content: space-between;

    &.collapsed {
      i.accor-plus-icon {
        &:before {
          content: "\F0415";
        }
      }
    }

    .accor-plus-icon {
      display: inline-block;
      font-size: 16px;
      height: 24px;
      width: 24px;
      line-height: 22px;
      background-color: $card-bg;
      text-align: center;
      border-radius: 50%;
    }
  }

  a {

    &.collapsed {
      i.accor-down-icon {
        &:before {
          content: "\F0140";
        }
      }
    }
  }

  .card-body {
    color: $text-muted;
  }
}



.transaction{
    .accordion{

        & > .card{
          margin-bottom: 15px;
          padding: 10px;
        }
        background-color: var(--general-bg);



        div[role*="tabpanel"]{
          border-top : 1px solid var(--general-border)
        }

        .card-header[id*="static"]{
          border-radius: unset !important;
          background-color: var(--general-bg);
          padding: 5px 0;
          // border-bottom: 1px solid var(--general-border);
        
      
          .btn-link{
            display: flex;
            align-items: center;
            padding-left: 0;
            border: none;


            &:not(.collapsed){
                i{
                    transform: rotate(0);
                }
            }

            i{
                margin-right: 10px;
                font-size: 20px;
                color: #8497ab;
                transition: transform .3s ease-in-out;
                transform: rotate(180deg);
            }


            span{
                display: inline-flex;
                margin-right: 10px;
                color: #495057;
                font-weight: 500;


            }
      
            &:focus,
            &:hover{
              outline: none;
              border: none;
              box-shadow: none;
              text-decoration: none;
            }
          }
        }



        .card-body{
            background-color: var(--general-bg);
        }
    }
}