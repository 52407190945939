.transaction-tooltip{
    background-color: #FFF;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.05);
    width: 300px;

    &__header{
        border-bottom: 1px solid #f2f2f3;
        display: flex;
        padding-bottom: 10px;
    }

    &__list{
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item{
        display: flex;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }


    &__value{
        color: #495057;
        opacity: 0.5;
        margin-left: 10px;
        margin-bottom: 0;
    }

    &__icon{
        border-radius: 5px;
        border: 1px solid #f2f2f3;
        width: 40px;
        height: 40px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        i{
            color: $primary;
            font-size: 18px;
        }

        &--small{
            width: 20px;
            height: 20px;
            i{
                font-size: 13px;
            }
        }
    }

    &__head{
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__heading{
        font-size: 12px;
        color: #495057;
        margin-bottom: 0;
    }
    &__dep{
        font-size: 10px;
        color: #495057;
        margin-bottom: 0;
        opacity: .5;
    }

    &__content{
        padding: 10px 0;
    }

    &__title{
        margin-left: 10px;
        display: inline-flex;
        color: #495057;
    }
}

.transaction-tooltip__custom{
    background-color: transparent !important;
    // opacity: 1 !important;

    &.tooltip.show{
        opacity: 1 !important;
    }
    .tooltip-inner{
        background-color: transparent !important;
    // opacity: 1 !important;

    }
}
