.inbox-page{
    margin: -23px -15px 0 -15px;
    @include largeScreen{
        height: calc(100vh - ($header-height + $footer-height - 10px));
    }
    .card{
        border:1px solid #e9e9e9;
        margin-bottom: 0;
        border-radius: 0;
        &.inbox-card{
            @include largeScreen{
                height: calc(100vh - ($header-height + $footer-height - 10px));
            }
            background-color: $sidebar-bg;
        }
    }

    .tab-pane{
        .card{
            border-color:  transparent !important;
        }
    }

    & > .row > .col-lg-3 {
        .card-body{
            padding: 0 !important;
        }
    }
    .inbox-actions{
        padding: 0 10px;
        .btn-link{
            padding: 0;
            border: 0;
            background-color: transparent;
            font-size: 12px;
            font-weight: 400;
            color: #878d95;
            text-decoration: none;
        }
        .action-bar-btn{
            padding: 0;
            border: 0;
            background-color: transparent;
            box-shadow: none;
            color: #3559A8;
            font-size: 18px;
            margin-inline-start: 5px;
            width: 35px;
            height: 35px;
            text-align: center;
            display: block;
            transition: all .3s ease-in-out;
            &:hover{
                color: $primary;
            }
            &.reversed{
                transform: rotateY(180deg);
            }
        }
    }

    .search-box{
        .nav-tabs .nav-link{
            padding: 0.5rem 0.2rem;
            font-size: 12px;
        }

        .form-control{
            padding-left: 27px;
        }
        .search-icon{
            left: 0;
        }
    }  

    .position-relative{
        .form-control{
            border-radius: 0 !important;
            border-color: transparent !important;
            border-bottom: 1px solid #eee !important;
            background-color: transparent;
        }
    }
   
}

.inbox-list{
    padding: 0;
    margin: 0;
    list-style-type: none;
    direction: rtl;
    // max-height: 69vh;
    @include largeScreen{
        height: calc(100vh - (263px + $footer-height));
    }
    overflow-y: auto;
    direction: ltr;
    text-align: end;

    .inbox-list__item{
        direction: ltr;
        border-top: solid 1px rgba(112, 112, 112, 0.05);
        border-bottom: solid 1px rgba(112, 112, 112, 0.05);
        margin-bottom: 5px;
        background-color: #FFF;
        
        .inbox-list__item-link{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            &.active{
                background-color: #cfe4fa;
            }
            cursor: pointer;
            .checkbox-wrapper-mail,
            .form-check{
                min-width: 20px;
            }
        }
        .inbox-account{
            display: flex;
            align-items: flex-start;
            width: 100%;
            .inbox-account__content{
                width: 100%;
            }
            .content-info{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: .5rem;
                .titles{
                    min-width: 50%;
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .inbox-account__name{
                    color: #3559a8;
                }
                .inbox-account__title{
                    color: #000;
                    font-size: 10px;
                }
                .cancel{
                    
                }
                .date-and-labels{
                    font-size: 10px;
                    text-align: end;

                    .btn{
                        padding: 5px 10px;
                        margin: 0 !important;
                        &[ngbtooltip="ارسال"]{
                            & > i {
                                @include rtl {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                   


                    .date{
                        color: #3559a8;
                        display: block;
                    }
                    .badge-bg-gray{
                        background-color: rgba(135, 141, 149, 0.34);
                        color: #878d95;
                    }
                }
            }
            .content-extra{
                font-size: 10px;
                color: #828991;
                font-weight: 300;
                text-align: left;
                p{
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

// inboxes global styles
.inbox-transaction-result{
    @include largeScreen{
        height: calc(100vh - ($header-height + $footer-height - 10px));
    }
    overflow-y: auto;
    padding-top: 8px;
    padding-right: 0;

    .nav-tabs {
        justify-content: unset !important;
        padding-left: 15px;
        .nav-link{
            height: 100%;
        }

    }
    .nav-link {
        padding: 0.5rem;
    }
}
// inboxes global styles



.accordion{
    .card{
        height: auto;
    }
}