.inbox-read{
    // height: calc(100% - 24px);
  

    .inbox-read__head{
        border-bottom: 1px solid #f2f2f5;
        padding-bottom: 15px;
        .head-action{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 25px;
            // border-bottom: 1px solid #f2f2f5;
            .action-bar-btn{
                padding: 0;
                border: 0;
                background-color: transparent;
                box-shadow: none;
                color: #3559A8;
                font-size: 18px;
                margin-inline-start: 5px;
                width: 35px;
                height: 35px;
                text-align: center;
                display: block;
                transition: all .3s ease-in-out;
                &:hover{
                    color: $primary;
                }
                &.reversed{
                    transform: rotateY(180deg);
                }
            }
        }

        .avatar-group{
            .avatar-group-item{
                span{
                    display: inline-flex;
                    background-color: #009688;
                    color: #fff;
                    font-size: 14px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .inbox-read__content{
        // height: calc(100vh - 24px);
        // overflow-y:auto;
        .inbox-message{
            padding: 25px 15px;
            color: #000;
            font-size: 14px;
            margin-bottom: 30px;
        }

        .attachments{
            .card.card--gray{
                padding: 0;
                background-color: #f8f8f8;
                border-radius: 8px;
            }
        }

        .attachment-actionBar{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .btn{
                padding: 0;
                border-radius: 8px;
                background-color: #f8f8f8;
                color: #6d8cba;
                font-size: 20px;
                width: 45px;
                height: 37px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .form-group{
        .form-label{
            color: #878d95;
            font-weight: 400;
        }
        textarea.form-control{
            resize: none;
            min-height: 50px;
            text-align: start;
            border-radius: 8px;
            border-color: #f8f8f8;
            &:read-only{
                background-color: #f8f8f8;
            }
        }
    }

    .inbox-account{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: .5rem;
        .titles{
            min-width: 50%;
            font-size: 16px;
        }
        .inbox-account__name{
            color: #3559a8;
            font-size: 16px;
            small{
                color: #b7b9bb;
                font-size: 10px;
            }
        }
        .inbox-account__title{
            color: #000;
        }
        .date-and-labels{
            font-size: 10px;
            text-align: end;
            .date{
                color: #828991;
                display: block;
            }
            .badge-bg-gray{
                background-color: rgba(135, 141, 149, 0.34);
                color: #878d95;
            }
        }
        .content-extra{
            font-size: 12px;
            color: #828991;
            font-weight: 300;
            p{
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}




