/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
// @import './assets/scss/app';


/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}





// ng select
.ng-select {

  .ng-select-container{
    padding: 0;

    &.ng-has-value{
      padding: 0 10px;
      // .ng-input{
      //   padding-left: 0 !important;
      // }
    }
  }

  .ng-option{
    text-align: left;
  }

  .ng-input{
    padding-left: 10px !important;
  }
  .ng-value{
    &:nth-child(2){
      margin-left: 0 !important;
    }
  }


  &.ng-select-single{
    .ng-placeholder{
      margin-left: 10px;
    }
  }



}


.form-group.parallel{
  margin-bottom: 25px !important;
  .invalid-feedback{
    position: absolute;
  }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #afafaf !important;
  padding-left: 3px;
}

.ng-select.ng-select-disabled{
   .ng-select-container {
    background-color: #dbdbdb!important;
  }
}

.btn{
  display:inline-flex !important;
  align-items: center;
}

i{
  cursor: pointer;
}
// ng select
@media screen{
  .partial-hidden{
    max-height: 0 !important;
    overflow: hidden !important;
    visibility: hidden !important;
    min-height: unset !important;
  }
}
  
.tox-notifications-container{
  display: none !important;
}


.side-nav-link-ref{
  &.active{
    color: #0d6efd !important;

    i{
      color: #0d6efd !important;
    }
  }
}


/* Handle Scroll style
==============================*/
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   transition: all 0.3s ease-in-out;
//   cursor: pointer;
// }
// /* Track */
// ::-webkit-scrollbar-track {
//   background: transparent;
//   border-radius: 0;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background-color: #2a3042;
//   transition: all 0.3s ease-in-out;
//   border-radius: 4px;
// }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #2a3042;
//   cursor: pointer;
// }
// .sm-scroll {
//   &::-webkit-scrollbar {
//     width: 4px;
//     height: 4px;
//   }
// }
// .inner-scroll {
//   &::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.51);
//   }
// }
.barcodeCanvas{
  visibility: hidden;
  width: 0;
  height: 0;
}

.default-page{
  table{
    width: 100%;
    tbody{
        tr > td {
            & > i {
                margin-right: 10px;
            }
        }
    }
}
}


.apex-charts{
  .apexcharts-legend-marker{
    margin-right: 10px !important;
  }
}


.card-body {
  padding: 15px !important;
}

.navbar-header{
  .user-name{
    text-align: left;
  }
}

.tooltip{
  // z-index: 1003 !important;
}


.hello{
  font-size: 10px;
}

.overflow-hidden-custom{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}


@media print {
  .inbox-card{
    display: none;
  }

  .barcodesContent{
    padding: 50px;
  }

  .images-screen{
    display: none;
  }
}

//styles for syncfusion
// @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
//styles for syncfusion


// .e-pv-main-container{
//   direction: rtl !important;
// }

.e-pv-sidebar-toolbar{
  z-index: 1;
}

.e-pv-sidebar-toolbar-splitter{
  z-index: 1;
}        


// @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';

// #_SKTLfeedback{
//   display: none !important;
// }

//search modal
.advancedSearch{
  max-width: 900px !important;
}
//search modal

//owl arrows
.owl-theme .owl-nav{
  justify-content: flex-start !important;
  margin-left: 15px;
}
//owl arrows

.full-screen-editor{
  position: fixed !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 1006 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  // padding-right: 15px;
  #mainFile,#attachViewer,#replayFile,#taskFile{
    height: 100% !important;
  }
  div[id*="viewerContainer"]{
    width: 100% !important;
  }

  ejs-documenteditorcontainer,ejs-pdfviewer{
    height: 100% !important;
  }
}

.toast-error,.toast-success{
  background-image:none !important;
}

.e-pv-strikethrough-container,
.e-pv-shape-template-container,
.e-pv-fontfamily-container,
.e-pv-fontsize-container,
.e-pv-text-color-container,
.e-pv-alignment-container,
.e-pv-text-properties-container,
.e-pv-color-template-container,
.e-pv-calibrate-template-container,
.e-separator,
.e-pv-annotation-freeTextEdit-container,
.e-pv-freetext-separator-container,
.e-pv-stamp-template-container,
.e-pv-annotation-ink-container,
.e-pv-sign-template-container,
.e-pv-stroke-template-container,
.e-pv-thickness-template-container,
.e-pv-opacity-template-container,
.e-pv-annotation-delete-container
{
  display: none !important;
}

.e-pv-sidebar-title-container{
  .e-pv-sidebar-title{
    display:  none !important;
  }
}


.editor-space {
    div[aria-label*="Open a document"]{
      display: none !important;
  }
}