// 
// _badge.scss
// 


.badge {
  &[href] {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
  &.bg-#{$color} {
    &[href] {
      &:hover,
      &:focus {
        background-color: darken($value, 4%) !important;
      }
    }
  }
}

.badge{
  &.bg-light{
    color: $body-color;

    &[href] {
      &:hover,
      &:focus {
        color: $body-color;
      }
    }
  }
}

  .badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }
  
}

.rounded-pill{
   padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge.bg-dark{
  color: $light;
}


.badge {
  display: inline-block;
  padding: 0.5em 0.5em;
  font-size: 92%;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;
  border-radius: 0.25rem;
}