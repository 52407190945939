// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  border-radius: .5rem;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--general-bg);
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.action-bar{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0.75rem;

  .form-check{
    margin-right: .5rem;
  }
  .action-bar-btn{
    border: 0;
    background-color: transparent;
    color: #3559A8;
    font-size: 22px;
    font-weight: bold;
  }
}


/* treeView-list
===============*/
.treeView-list{
  padding: 0;
  padding-left: 20px;
  list-style-type: none;
  .treeView-item{
    position: relative;
    margin-bottom: 5px;
    &::before{
      position: absolute;
      font-weight: 300;
      top: 5px;
      left: -20px;
      content: "\f053";
      font-family: 'Font Awesome 6 Pro';
      display: block;
      color: #3559a8;
      font-size: 16px;
    }
    &.is-open{
      // > .treeView-item__link{
      //   background-color: rgba(3, 42, 172, 0.06);
      // }

      & > .treeView-list{
        max-height: 1000px;
      }

      &::before{
        content: "\f078";
      }
    }
    > .treeView-item__link{
      cursor: pointer;
      color: #3559a8;
      font-size: 16px;
      display: block;
      margin-bottom: 5px;
      padding: 5px 10px;
      border-radius: 4px;
      &.active{
        background-color: rgba(3, 42, 172, 0.06);
      }
    }
    .treeView-list{
      max-height: 0;
      overflow: hidden;
      transition: all 0.4s ease;
    }
  }
}