//
// Google font - Poppins
//


@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-Bold.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-SemiBold.ttf"); /* IE9 Compat Modes */
}
 


@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-Medium.ttf"); /* IE9 Compat Modes */
}



@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-Regular.ttf"); /* IE9 Compat Modes */
}



@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-Light.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 200;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-ExtraLight.ttf"); /* IE9 Compat Modes */
}


@font-face {
    font-family: "IBM";
    font-style: normal;
    font-weight: 200;
    src: url("/assets/fonts/IBM_Plex/IBMPlexSansArabic-Thin.ttf"); /* IE9 Compat Modes */
}



@font-face {
    font-family: "EXPO";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/expo/Expo Arabic Light.ttf"); /* IE9 Compat Modes */
}
@font-face {
    font-family: "EXPO";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/expo/Expo Arabic Medium.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "EXPO";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/expo/Expo Arabic Bold.ttf"); /* IE9 Compat Modes */
}


@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');