.form-control,.ng-select .ng-select-container{
    border: 1px solid #eeeeee !important;
    // &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    //     color: red;
    //     opacity: 1; /* Firefox */
    //   }
      
    //   &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    //     color: red;
    //   }
      
    //   &::-ms-input-placeholder { /* Microsoft Edge */
    //     color: red;
    //   }
}