@mixin rtl {
    html[dir="rtl"] & {
      @content;
    }
}

@mixin largeScreen {
  @media(min-width:1200px)  {
    & {
      @content;
    }
  }
}

  